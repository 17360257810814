<template>
  <v-card>
    <v-form @submit.prevent="updateItem" ref="form">
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between"
      >
        <span v-if="item">
          {{
            $t("recommender.editSettingsFor", {
              scope: getServiceName({ scope }),
            })
          }}
        </span>
        <span v-else>
          {{
            $t("recommender.addSettingsFor", {
              scope: getServiceName({ scope }),
            })
          }}
        </span>
        <v-btn icon color="white" :title="$t('close')" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="px-3" style="position: relative">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <v-card-text>
          <v-text-field
            :rules="[(v) => !!v || $t('defaultErrors.required')]"
            name="name"
            :label="attributeLabels.title"
            v-model="settings.title"
            type="text"
          ></v-text-field>
          <div v-if="item && item.base">
            <span class="subtitle-1 pb-3 font-weight-bold">
              {{ $t("recommender.segmentSettings") }}:
            </span>
            {{ $t("recommender.allAccounts") }}
          </div>
          <div v-else>
            <div class="subtitle-1 pb-3 font-weight-bold">
              {{ $t("recommender.segmentSettings") }}
            </div>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  :clearable="true"
                  :items="tags"
                  item-text="title"
                  item-value="id"
                  :loading="isTagLoading"
                  :search-input.sync="tagSearch"
                  menu-props="closeOnContentClick"
                  @change="tagSearch = ''"
                  deletable-chips
                  multiple
                  small-chips
                  hide-no-data
                  hide-selected
                  name="tags"
                  :label="attributeLabels.tagId"
                  v-model="settings.segment.tagId"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  :clearable="true"
                  :items="availableAccountTypeLabels"
                  item-text="title"
                  item-value="id"
                  menu-props="closeOnContentClick"
                  deletable-chips
                  multiple
                  small-chips
                  hide-no-data
                  hide-selected
                  name="type"
                  :label="attributeLabels.type"
                  v-model="settings.segment.type"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  :clearable="true"
                  :items="availableStages"
                  :label="attributeLabels.stage"
                  v-model="settings.segment.stage"
                  menu-props="closeOnContentClick"
                  deletable-chips
                  multiple
                  small-chips
                  hide-no-data
                  hide-selected
                  name="language"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  :clearable="true"
                  item-text="key"
                  item-value="gender"
                  :items="availableGenders"
                  :label="this.attributeLabels.gender"
                  v-model="settings.segment.gender"
                  menu-props="closeOnContentClick"
                  deletable-chips
                  multiple
                  small-chips
                  hide-no-data
                  hide-selected
                  name="gender"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  :clearable="true"
                  :items="availableAges"
                  :label="this.attributeLabels.ageGroup"
                  v-model="settings.segment.ageGroup"
                  menu-props="closeOnContentClick"
                  deletable-chips
                  multiple
                  small-chips
                  hide-no-data
                  hide-selected
                  name="ageGroup"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  :clearable="true"
                  item-text="label"
                  item-value="key"
                  :items="availableLanguageLabels"
                  :label="this.attributeLabels.language"
                  v-model="settings.segment.language"
                  menu-props="closeOnContentClick"
                  deletable-chips
                  multiple
                  small-chips
                  hide-no-data
                  hide-selected
                  name="language"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
          <div class="mt-5">
            <div class="subtitle-1 mb-3 font-weight-bold">
              {{ $t("recommender.matchingSettings") }}
            </div>
            <div>
              <v-slider
                v-model="settings.matching.geo"
                :label="attributeLabels.matching.geo"
                max="1"
                min="0"
                step="0.01"
                thumb-label="always"
                class="mb-3"
              ></v-slider>
              <v-slider
                v-model="settings.matching.interest"
                :label="attributeLabels.matching.interest"
                max="1"
                min="0"
                step="0.01"
                thumb-label="always"
                class="mb-3"
              ></v-slider>
              <v-row>
                <v-col cols="7">
                  <v-alert
                    v-if="totalTagSum > 1"
                    dense
                    outlined
                    type="error"
                    class="mt-6 pa-2 text-caption"
                  >
                    Сумма тегов превышает единицу
                  </v-alert>
                  <v-row
                    v-for="(item, index) in settings.matching.fuzzyTags"
                    :key="index"
                    class="mt-4"
                  >
                    <v-col cols="7">
                      <v-text-field
                        :label="$t('recommender.tagSlug')"
                        v-model="settings.matching.fuzzyTags[index].tagSlag"
                        hide-details="auto"
                        :rules="[(v) => !!v || $t('defaultErrors.required')]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="settings.matching.fuzzyTags[index].value"
                        hide-details="auto"
                        v-mask="'0.###'"
                        :rules="[(v) => !!v || $t('defaultErrors.required')]"
                        required
                      >
                        <v-icon
                          small
                          slot="append"
                          color="green"
                          @click="increase(index)"
                        >
                          mdi-plus
                        </v-icon>
                        <v-icon
                          small
                          slot="prepend"
                          color="red"
                          @click="decrease(index)"
                        >
                          mdi-minus
                        </v-icon>
                      </v-text-field>
                    </v-col>
                    <v-col cols="1" class="pt-4">
                      <v-icon @click="removeTag(index)">mdi-close</v-icon>
                    </v-col>
                  </v-row>
                  <v-chip
                    small
                    outlined
                    color="primary"
                    class="mt-6"
                    @click="addTag"
                  >
                    + {{ $t("add") }}
                  </v-chip>
                </v-col>
                <v-col cols="5">
                  <vector-doughnut
                    :chartData="tagDataset"
                    :options="options"
                  ></vector-doughnut>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-alert v-if="isSegmentExist" dense outlined type="error">
            {{ $t("recommender.segmentExist") }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
            v-if="!item || !item.base"
            name="enabled"
            :label="attributeLabels.status"
            v-model="settings.status"
            true-value="active"
            false-value="inactive"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="$emit('close')">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="blue darken-1" text type="submit">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import _ from "lodash";

import { mapGetters } from "vuex";

import { FETCH_TAGS_PARTIAL } from "@/store/actions/tag";
import { PATCH_CONTENT_SETTINGS } from "@/store/actions/recommender";
import { TYPE_CONTENT } from "@/store/modules/tag/getters";

import VectorDoughnut from "./VectorDoughnut";
import { FETCH_ACCOUNT_TYPES } from "@/store/actions/donor";

export default {
  name: "ContentRecommenderForm",
  components: { VectorDoughnut },
  props: {
    item: Object,
    segment: Object,
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: 0,
      dataLoading: false,
      formErrors: null,
      settings: this.item
        ? {
            title: this.item.title,
            target: this.scope,
            segment: this.item.segment,
            matching: this.item.matching,
            status: this.item.status,
          }
        : {
            title: "",
            target: this.scope,
            segment: this.segment,
            matching: {
              geo: 0,
              interest: 0,
              fuzzyTags: [],
            },
            status: "inactive",
          },
      attributeLabels: {
        gender: this.$t("account.attribute.gender"),
        type: this.$t("account.attribute.type"),
        ageGroup: this.$t("account.attribute.age"),
        language: this.$t("account.attribute.language"),
        tagId: this.$t("account.attribute.tags"),
        stage: this.$t("account.attribute.stage"),
        status: this.$t("recommender.settingsStatus"),
        matching: this.$t("recommender.content.matching"),
        title: this.$t("recommender.title"),
      },
      options: {
        responsive: true,
        legend: {
          position: "top",
          labels: {
            padding: 5,
            pointStyle: "circle",
            font: {
              size: 10,
              lineHeight: 1,
            },
          },
          onClick: function () {
            return false;
          },
        },
        animations: {
          tension: {
            duration: 0,
            easing: "linear",
            from: 1,
            to: 0,
            loop: false,
          },
        },
      },
      //Filter values status
      isTagLoading: false,
      isGroupLoading: false,
      //Filter values
      tags: [],
      //Filter entered value
      tagSearch: null,
      availableAccountTypeLabels: [],
    };
  },
  computed: {
    ...mapGetters("content", [
      "availableScopes",
      "availableActions",
      "availableSegments",
    ]),
    ...mapGetters("account/donor", [
      "availableGenders",
      "availableLanguages",
      "availableStages",
      "availableAges",
      "availableLanguageLabels",
    ]),
    ...mapGetters("service", ["getServiceName"]),
    totalTagSum: function () {
      const total = _.map(this.settings.matching.fuzzyTags, "value").reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      return Math.round(parseFloat(total) * 100) / 100;
    },
    availableSegmentLabels: function () {
      let component = this;
      let result = [];
      this.availableSegments.forEach(function (segment) {
        result.push({
          key: segment,
          label: _.get(component.attributeLabels, segment),
        });
      });
      return result;
    },
    formattedSegment: function () {
      let segment = {};
      _.forIn(this.settings.segment, function (value, key) {
        if (value.length) {
          segment[key] = value;
        }
      });
      return segment;
    },
    hasFormattedSegment: function () {
      return !this.isEmpty(this.formattedSegment);
    },
    isSegmentExist: function () {
      return (
        this.formErrors &&
        this.formErrors.segment &&
        this.formErrors.segment.indexOf("duplicate_segment") > -1
      );
    },
    tagDataset: function () {
      const vm = this;
      const availableColors = [
        "#90CAF9",
        "#BBDEFB",
        "#B3E5FC",
        "#B2EBF2",
        "#B2DFDB",
        "#A5D6A7",
        "#C8E6C9",
        "#DCEDC8",
        "#F0F4C3",
        "#FFF9C4",
        "#FFECB3",
        "#FFE0B2",
        "#FFCCBC",
        "#FFCDD2",
        "#F8BBD0",
        "#E1BEE7",
        "#D1C4E9",
        "#C5CAE9",
        "#B0BEC5",
        "#D7CCC8",
      ];
      let labels = [];
      let data = [];
      let colors = [];
      let left = 1;
      let index = 0;
      vm.settings.matching.fuzzyTags.forEach(function (item) {
        data.push(item.value);
        labels.push(item.tagSlag);
        left -= item.value;
        colors.push(availableColors[index % availableColors.length]);
        ++index;
      });
      if (left > 0) {
        data.push(Math.round(parseFloat(left) * 100) / 100);
        labels.push("Не установленно");
        colors.push("#CFD8DC");
      }
      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colors,
          },
        ],
      };
    },
  },
  watch: {
    tagSearch: function (value) {
      this.isTagLoading = true;
      this.$store
        .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
          limit: 20,
          offset: 0,
          search: {
            title: value,
            level: 1,
            excludeType: [TYPE_CONTENT],
          },
        })
        .then(
          (payload) => {
            this.isTagLoading = false;
            this.tags = payload.data.batch;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  methods: {
    updateItem: function () {
      if (this.$refs.form.validate() && this.totalTagSum <= 1) {
        this.dataLoading = true;
        let { settings } = this;
        settings.target = this.scope;
        this.$store
          .dispatch("content/" + PATCH_CONTENT_SETTINGS, {
            settings,
            id: this.item ? this.item.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.$emit("submitted");
              } else {
                this.formErrors = payload.errors;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    addTag: function () {
      this.settings.matching.fuzzyTags.push({
        tagSlag: "",
        value: null,
      });
    },
    removeTag: function (index) {
      this.settings.matching.fuzzyTags.splice(index, 1);
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    increase: function (index) {
      let value =
        this.settings.matching.fuzzyTags[index].value < 0.99
          ? this.settings.matching.fuzzyTags[index].value + 0.01
          : 1;
      this.settings.matching.fuzzyTags[index].value =
        Math.round(parseFloat(value) * 100) / 100;
    },
    decrease: function (index) {
      let value =
        this.settings.matching.fuzzyTags[index].value > 0.01
          ? this.settings.matching.fuzzyTags[index].value - 0.01
          : 0;
      this.settings.matching.fuzzyTags[index].value =
        Math.round(parseFloat(value) * 100) / 100;
    },
    fetchAccountTypes: function () {
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_TYPES, {
          limit: 50,
          offset: 0,
        })
        .then(
          (payload) => {
            this.availableAccountTypeLabels = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted: function () {
    this.fetchAccountTypes();
    this.$store
      .dispatch("tag/" + FETCH_TAGS_PARTIAL, {
        limit: 20,
        offset: 0,
        search: {
          id: this.settings.segment.tagId,
          level: 1,
          excludeType: [TYPE_CONTENT],
        },
      })
      .then(
        (payload) => {
          if (payload.status) {
            this.tags = payload.data.batch;
          }
        },
        (err) => {
          console.log("Err", err);
        }
      )
      .catch(() => {});
  },
};
</script>

<style scoped>
.row + .row,
.col,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 0;
  padding-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
